<template>
  <div class="back-home-body">
        <!-- CAROUSEL-->
      
    <b-row no-gutters align-v="center">
      <b-col cols="12" md="6" style="padding:0px; ">
         <b-img style="width:100%;" :src="require('../assets/mestiza1.jpeg')" fluid >></b-img>
      </b-col>
      <b-col cols="12" md="6" style="padding:0px; ">
         <b-img style="width:100%;" :src="require('../assets/mestizacol.jpg')" fluid >></b-img>
      </b-col>
    </b-row>

    <b-row align-h="center">
      <b-col cols="11" md="10" lg="8" xl="8" class="mt-3">
        <b-row align="left">
          <b-col>
            <h5><b>Propiedad 104</b></h5>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-row align-h="center">
      <b-col cols="11" md="10" lg="8" xl="8" style="background-color: #E8E8E7; border-radius:25px; padding:5px;" class="mt-4 p-text">
        <br>
        <p>
          These condos symbolize luxury in every sense of the word. Embrace an elegant lifestyle with a sense of comfort and well-being that is only experienced by the lucky few. The natural environment that surrounds Mestiza invites you to connect with Mother Earth,who embraces you completely making you feel at home.
        </p>
        <p>
          This project reflects the honesty and simplicity of the Yucatecan and African architecture, with its warm color palette and natural textures.
        </p>
        <br>
      </b-col>
    </b-row>

    <b-row align="center">
      <b-col class="mt-4" >
        <b-row align-h="center">
          <b-col cols="10" sm="8" md="4" lg="3" xl="3">
            <b-row align-h="center">
              <b-col class="mt-4" cols="12"><b-img style="height:190px" :src="require('../assets/Susyfinalmente.png')" fluid ></b-img></b-col>
              <b-col class="mb-4 p-text mt-4" cols="12">
                <h6>Contact</h6>
                <h6>info@susimacdonald.com</h6>
                <h6>+52 984 167 4506</h6>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="11" sm="11" md="8" lg="5" xl="3" class="mt-4 mb-4 " >
            <b-row align-h="left" align="left">
              <div  style="background-color:transparent; padding:17px;">
                <b-col cols="12" class="mb-3"><FormulateInput label-class="form-label-class" input-class="form-input"  label="Name" type="text" validation="" v-model="form.name"/></b-col>
                <b-col cols="12" class="mb-3"><FormulateInput label-class="form-label-class" input-class="form-input"  label="Email" type="email" validation="email" v-model="form.email"/></b-col>
                <b-col cols="12" class="mb-3"><FormulateInput label-class="form-label-class" input-class="form-input"  label="Phone" type="text" validation="" v-model="form.phone"/></b-col>
                <b-col cols="12" class="mb-3"><FormulateInput label-class="form-label-class" input-class="form-input"  label="Comment" type="textarea" validation="" v-model="form.comment"/></b-col>      
                <b-col cols="12"><b-button @click="enviarMail">Send</b-button></b-col> 
              </div>    

              <form  ref="form" >
                <input v-show="false" id="name" name="name" type="text"  v-model="form.name"> 
                <input v-show="false" id="email" name="email" type="text"  v-model="form.email"> 
                <input v-show="false" id="phone" name="phone" type="text"  v-model="form.phone"> 
                <input v-show="false" id="interest" name="interest" type="text"  v-model="form.interest"> 
                <input v-show="false" id="comment" name="comment" type="text"  v-model="form.comment"> 
              </form>
              
            </b-row>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

  </div>
</template>
<script>
  import { mapState} from 'vuex'
  import emailjs from '@emailjs/browser';

  export default {
    components: {
    },
    computed: {
      ...mapState({
        
      }),
    },
    data() {
      return {
        form:{
          name:"",
          email:"",
          phone:"",
          interest:["Real State"],
          comment:""
        }
      }
    },
    methods: {
      enviarMail(){
        emailjs.sendForm('service_6nsrtfm', 'template_m0kyzjk', this.$refs.form, 'W8YrTfKVZ2WwQY5A-')
        .then((result) => {
            alert('Gracias por tu registro!', result.text);
            this.form.name="";
            this.form.email="";
            this.form.phone = "";
            this.form.interest=[];
            this.form.comment = "";
        }, (error) => {
            alert('Tu registro no se completo. Intenta más tarde', error.text);
        });
      }
    },
  }
</script>
<style scoped>
@import url('../assets/snow.min.css'); 

.back-home-body{
  background-image: url('../assets/texturasusy.jpg'); 
  background-size: auto;
}

</style>